import React, { useState } from 'react'
import ModalForm from "components/common/form/ModalForm"
import { Form } from 'antd';

function UserForm(): JSX.Element {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm();

  return (
    <ModalForm
      title="添加用户"
      submitPath="/admin/users"
      submitMethod="POST"
      buttonType='primary'
      buttonText='添加用户'
      form={form}
      showActionButtons={true}
      modalProps={{
        width: 500,
        open: open,
      }}
      formData={[
        {
          key: 'fd',
          label: '姓名',
          type: 'input',
          rules: [{ required: true }]
        },
        {
          key: 'name',
          label: '账号',
          type: 'input',
          rules: [{ required: true }],
        },
        {
          key: 'pass',
          label: '密码',
          type: 'input',
          rules: [{ required: true }],
        },
      ]}
    />
  )
}

export default UserForm
