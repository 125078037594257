import React from 'react'

function Index(): JSX.Element {

  return (
    <div>
      管理后台首页
    </div>
  )
}

export default Index