import React, { ReactNode } from 'react'
import { Form as AntdForm, Button, message } from 'antd'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react'
import Modal, { ModalRef, ModalProps } from '../modal'
import { CommonExtendFormProps } from './form'
import Form, { FormProps, FormType } from '.'
import { useToggle } from 'ahooks'
import classnames from 'classnames'

export type ModalFormRef = Partial<FormType & ModalRef>

export interface ModalFormProps extends Partial<CommonExtendFormProps>, Omit<FormProps, 'children'> {
  modalProps?: Omit<ModalProps, 'trigger'>
  submitPath?: string,
  submitMethod?: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  buttonType?: "primary" | "default" | "dashed" | "link" | "text",
  buttonText?: string | ReactNode,
}

const ModalForm: ForwardRefRenderFunction<ModalFormRef, ModalFormProps> = (
  {
    title,
    modalProps,
    onSubmit,
    onVisibleChange,
    okText = '确认',
    cancelText = '取消',
    submitPath,
    submitMethod,
    buttonType = 'primary',
    buttonText = '确认',
    ...formProps
  },
  ref
) => {
  const [currentForm] = AntdForm.useForm()
  const formRef = useRef<FormType>(null)
  const modalRef = useRef<ModalRef>(null)
  const form = formProps.form ?? currentForm
  const [open, { toggle }] = useToggle(modalProps?.open || false)

  useImperativeHandle(
    ref,
    () => ({
      ...(formRef.current || {}),
      ...(modalRef.current || {}),
    }),
    []
  )

  const handleCancel = () => {
    toggle()
    modalProps?.onClose?.()
  }

  return (
    <>
      <Button type={buttonType} onClick={toggle}>
        {buttonText}
      </Button>
      <Modal
        ref={modalRef}
        okText={okText}
        cancelText={cancelText}
        {...modalProps}
        open={open}
        onVisibleChange={onVisibleChange ?? modalProps?.onVisibleChange}
        title={title}
        className={classnames({
          "modalForm": true,
          [modalProps?.className]: !!modalProps?.className,
        })}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        <Form
          showActionButtons={false}
          // @ts-ignore
          ref={formRef}
          form={form}
          card={false}
          submitPath={submitPath}
          submitMethod={submitMethod}
          submitSuccess={() => {
            toggle()
          }}
          cancel={() => {
            toggle()
          }}
          {...formProps}
        />
      </Modal>
    </>
  )
}

export default forwardRef(ModalForm)
