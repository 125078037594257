import { Form as AntForm, Checkbox, DatePicker, Input, InputNumber, Radio, Select, Switch } from "antd";
import React, { FC, useMemo } from "react";
import CropImageUploader from "../CropImageUploader";
import BaseDatePicker from "./fields/BaseDatePicker";
import BaseSelect from "./fields/BaseSelect";
import { FormItemType } from "./consts";

interface FormItemProps {
  item: any
  index?: any
}

const FormItem: FC<FormItemProps> = ({
  item,
  index
}) => {
  const renderItemType = useMemo(() => {
    switch (item.type) {
      case FormItemType.Input:
        return (
          <Input {...item} />
        )
      case FormItemType.Textarea:
        return (
          <Input.TextArea {...item} />
        )
      case FormItemType.Select:
        return (
          <BaseSelect
            name={item.key}
            {...item}
          />
        )
      case FormItemType.InputNumber:
        return (
          <InputNumber {...item} />
        )
      case FormItemType.Switch:
        return (
          <Switch {...item} />
        )
      case FormItemType.Checkbox:
        return (
          <Checkbox {...item} />
        )
      case FormItemType.CheckboxGroup:
        return (
          <Checkbox.Group>
            {item.options.map((option: any) => (
              <Checkbox value={option.value}>{option.label}</Checkbox>
            ))}
          </Checkbox.Group>
        )
      case FormItemType.Radio:
        return (
          <Radio {...item} />
        )
      case FormItemType.RadioGroup:
        return (
          <Radio.Group>
            {item.options.map((option: any) => (
              <Radio value={option.value}>{option.label}</Radio>
            ))}
          </Radio.Group>
        )
      case FormItemType.Upload:
        return (
          <CropImageUploader {...item} />
        )
      case FormItemType.DatePicker:
        return (
          <BaseDatePicker
            name={item.key}
            {...item}
          />
        )
      case FormItemType.DateRangePicker:
        return (
          <DatePicker.RangePicker {...item} />
        )
      default:
        return <p>该组件暂未支持</p>
    }
  }, [item])

  return (
    <AntForm.Item
      {...item}
      key={(typeof item.key === 'object' ? item.key.join(',') : item.key) || index}
      name={item.key}
    >
      {renderItemType}
    </AntForm.Item>
  )
}

export default FormItem